<template>
  <div class="article">
    <div class="receiving tbl_wrap">
      <div class="tbl_option">
        <h5>원자재 기준 입고처리</h5>
      </div>
      <h6>조회수 : {{ filteredPurchaseMaterialList.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 8" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>원자재명</th>
              <th>구매처</th>
              <th>수량(단위)</th>
              <th>수입검사여부</th>
              <th>단가</th>
              <th>지출액</th>
              <th>발주일자</th>
              <th>입고예정일</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in filteredPurchaseMaterialList"
              @click="selectRow(index)"
              :key="item.id"
              :class="{ active: index == selectedIndex }"
            >
              <td class="text_left">
                {{ getMaterialInfo(item.material_id).name }}
              </td>
              <td class="text_left">
                {{ getCompanyInfo(item.company_id).name }}
              </td>
              <td class="text_right">
                {{
                  `${item.quantity}(${getUnitName(item.unit_id)})` | makeComma
                }}
              </td>
              <td>
                {{
                  getMaterialInfo(item.material_id).inspection ? '검사' : '생략'
                }}
              </td>
              <td class="text_right">&#8361; {{ item.cost | makeComma }}</td>
              <td class="text_right">
                &#8361; {{ item.total_cost | makeComma }}
              </td>
              <td>{{ item.input_date | formatDateNoHours }}</td>
              <td>{{ item.incoming_date | formatDateNoHours }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="form">
        <form @submit.prevent>
          <div class="input_text">
            <label>입고일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="input_date"
              @change="setInputDate($event)"
              :readonly="selectedIndex == -1 || !isPermissionOn"
            />
          </div>
          <div class="input_text">
            <label>원자재명</label>
            <input
              type="text"
              id="rawMaterial"
              readonly
              placeholder=""
              :value="getMaterialInfo(managementData.material_id).name"
            />
          </div>
          <div class="input_text">
            <label>구매처명</label>
            <input
              type="text"
              id="purchase"
              readonly
              placeholder=""
              :value="getCompanyInfo(managementData.company_id).name"
            />
          </div>
          <div class="ea">
            <div class="input_text">
              <label>수량</label>
              <input
                type="text"
                id="quantity"
                placeholder=""
                :value="$makeComma(managementData.quantity)"
                inputmode="decimal"
                @input="$inputNumber($event, managementData, 'quantity')"
                :disabled="selectedIndex == -1 || !isPermissionOn"
              />
            </div>
            <div class="input_text">
              <label>단위</label>
              <input
                type="text"
                id="ea"
                readonly
                placeholder=""
                :value="getUnitName(managementData.unit_id)"
              />
            </div>
          </div>
          <div class="input_text">
            <label>입고창고</label>
            <my-selectric
              :id="`store_selectric`"
              :watch="managementData.store_id"
              :options="store_options"
              :state="selectedIndex == -1 || !isPermissionOn ? 'disabled' : ''"
              :commit="'setMaterialManagementStoreIdToInput'"
              :key="reRend"
              @refresh="changeKey($event)"
            >
            </my-selectric>
          </div>
          <div class="input_text">
            <label>세부위치</label>
            <my-selectric
              v-if="managementData.store_id != null"
              :id="`location_selectric`"
              :watch="managementData.location_id"
              :options="managementData.location_options"
              :state="selectedIndex == -1 || !isPermissionOn ? 'disabled' : ''"
              :commit="'setMaterialManagementLocationIdToInput'"
              :key="reRend2"
              @refresh="changeKey2($event)"
            >
            </my-selectric>
          </div>
          <div class="input_text">
            <label>단가</label>
            <input
              type="text"
              id="unitPrice"
              placeholder=""
              :value="$makeComma(managementData.cost)"
              inputmode="decimal"
              @input="$inputNumberInt($event, managementData, 'cost')"
              :disabled="selectedIndex == -1 || !isPermissionOn"
            />
          </div>
          <div class="input_text">
            <label>공급가액</label>
            <input
              type="text"
              id="supplyValue"
              placeholder=""
              readonly
              :value="
                managementData != undefined &&
                managementData.vat_id != undefined &&
                managementData.tax_id != undefined
                  ? '₩ ' +
                    makeComma(
                      calSupplyTax(
                        managementData.vat_id,
                        managementData.tax_id,
                        $makeNumber(managementData.quantity),
                        $makeNumber(managementData.cost),
                      ).supply,
                    )
                  : ''
              "
            />
          </div>
          <div class="input_text">
            <label>부가세액</label>
            <input
              type="text"
              id="taxAmount"
              placeholder=""
              readonly
              :value="
                managementData != undefined &&
                managementData.vat_id != undefined &&
                managementData.tax_id != undefined
                  ? '₩ ' +
                    makeComma(
                      calSupplyTax(
                        managementData.vat_id,
                        managementData.tax_id,
                        $makeNumber(managementData.quantity),
                        $makeNumber(managementData.cost),
                      ).tax,
                    )
                  : ''
              "
            />
          </div>
          <div class="input_text">
            <label>지출액</label>
            <input
              :disabled="selectedIndex == -1 || !isPermissionOn"
              type="text"
              id="disbursement"
              :value="
                managementData != undefined &&
                managementData.vat_id != undefined &&
                managementData.tax_id != undefined
                  ? makeComma(
                      calSupplyTax(
                        managementData.vat_id,
                        managementData.tax_id,
                        $makeNumber(managementData.quantity),
                        $makeNumber(managementData.cost),
                      ).total,
                    )
                  : '0'
              "
              inputmode="decimal"
              @blur="
                $event =>
                  ($event.target.value = $makeComma(
                    calSupplyTax(
                      managementData.vat_id,
                      managementData.tax_id,
                      $makeNumber(managementData.quantity),
                      $makeNumber(managementData.cost),
                    ).total,
                  ))
              "
              @keydown.enter.prevent="$event => reCalCost($event.target.value)"
              @keypress="onlyNumber($event)"
              @input="$inputNumberIntNoTarget($event)"
            />
          </div>
          <div class="btn_wrap">
            <!-- <div>
              <button class="btn_sub1">수입검사 진행</button>
            </div> -->
            <div>
              <button
                class="btn_sub2"
                @click="submitForm"
                v-if="selectedIndex != -1 && isPermissionOn"
              >
                입고 처리
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import PriceMixin from '@/mixins/material_price';
import MySelectric from '@/layouts/components/MySelectric.vue';
import FavoriteMixin from '@/mixins/favorite';
import { yyyymmdd } from '@/utils/func';
import { mapGetters } from 'vuex';

export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin, PriceMixin, FavoriteMixin],
  components: {
    MySelectric,
  },
  data() {
    return {
      reRend: 1,
      reRend2: 1,
      show_store_selectric: false,
    };
  },
  computed: {
    ...mapGetters({
      order_purchases_type: 'getOrderPurchaseType',
      draft_purchases_type: 'getDraftPurchaseType',
      // purchases: 'getOrderPurchaseOnlyInputNotCompleted',
      selectedIndex: 'getMaterialSelectedIndexFromInput',
      purchase_material_purchase: 'getPurchaseMaterialPurchaseOnlyNotInput',
      managementData: 'getMaterialManagementDataFromInput',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      input_date: 'getMaterialBaseInputDateFromInput',
    }),
    filteredPurchaseMaterialList() {
      if (this.purchase_material_purchase.length > 0) {
        return this.lodash
          .clonedeep(this.purchase_material_purchase)
          .sort(
            (a, b) =>
              new Date(b.input_date) - new Date(a.input_date) || b.id - a.id,
          );
      } else return [];
    },
    checkTotalCostValid() {
      if (
        this.managementData != undefined &&
        this.managementData.vat_id != undefined &&
        this.managementData.tax_id != undefined
      ) {
        const calPrice = this.calSupplyTax(
          this.managementData.vat_id,
          this.managementData.tax_id,
          this.$makeNumber(this.managementData.quantity),
          this.$makeNumber(this.managementData.cost),
        ).total;
        if (calPrice <= 2147483647) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    checkQuantityZero() {
      if (this.selectedIndex != -1) {
        let chkData = this.lodash.clonedeep(this.managementData).quantity;
        if (this.$makeNumber(chkData) == 0) {
          return false;
        }

        return true;
      } else return false;
    },
  },
  methods: {
    reCalCost(newPurchaseValue) {
      // row
      const total_value = this.$makeNumber(newPurchaseValue);
      console.log('total_value', total_value);
      const company_vat = this.managementData.vat_id;
      const product_tax = this.managementData.tax_id;
      let rowQuantity = this.$makeNumber(this.managementData.quantity);
      if (rowQuantity === 0) {
        this.managementData.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        this.managementData.cost = 0;
        this.managementData.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        this.managementData.cost = 0;
        this.managementData.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
    },
    changeKey() {
      this.reRend += 1;
      const options = this.getStoreLocationOptions(
        this.managementData.store_id,
      );
      this.managementData.location_options = options;
      this.managementData.location_id = null;
    },
    changeKey2() {
      this.reRend2 += 1;
    },
    setInputDate(e) {
      if (e.target.value != '') {
        this.$store.commit('setMaterialBaseInputDateToInput', e.target.value);
      } else {
        e.target.value = this.input_date;
        this.openOneButtonModal(
          '주의',
          '입고일을 삭제할 수 없습니다.<br/> 다른 날짜를 선택해주세요.',
        );
        this.$store.commit(
          'setMaterialBaseInputDateToInput',
          yyyymmdd(new Date()),
        );
      }
    },
    makeComma(value) {
      if (value == undefined) return '';
      if (value.length == 0) {
        return '0';
      }
      value = Number(String(value).replace(/,/gi, ''));
      if (value == 0) {
        return '0';
      }
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getStoreLocationOptions(id) {
      // console.log(this.material_lists[id].store_id);
      const storeInfo = this.getStoreInfo(id);

      let options = [];
      options.push({ label: '선택', value: null, detail: '' });

      if (storeInfo != undefined) {
        console.log(storeInfo);
        const types = storeInfo.locations.sort((a, b) => a.id - b.id);
        types.forEach(el => {
          options.push({ label: el.name, value: el.id, detail: el.detail });
        });
      }
      return options;
    },
    ShowModal() {
      this.my_modal_title = '입고처리';
      this.my_modal_content = '한개의 발주항목을 먼저 입고처리하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_isModalOpen = false;
    },
    async submitForm() {
      if (this.checkTotalCostValid && this.checkQuantityZero) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.input_yn = true;
        payload.completed = true;
        payload.cost = this.$makeNumber(payload.cost);
        payload.quantity = this.$makeNumber(payload.quantity);
        const recal_costs = this.lodash.clonedeep(
          this.calSupplyTax(
            payload.vat_id,
            payload.tax_id,
            payload.quantity,
            payload.cost,
          ),
        );
        payload.supply_value = recal_costs.supply;
        payload.tax = recal_costs.tax;
        payload.total_cost = recal_costs.total;
        payload.input_date = this.lodash.clonedeep(this.input_date);
        payload.purchase_materials = this.lodash.clonedeep(payload);
        this.showSpinner();
        this.$store
          .dispatch('UPDATE_ONE_ELEMENT_MATERIAL_WITH_PURCHASE', payload)
          .then(async () => {
            this.openOneButtonModal(
              '등록 성공',
              '자재를 성공적으로 입고처리하였습니다.',
            );
            await this.FETCH(
              'FETCH_PURCHASE_MATERIAL_PURCHASE',
              '미입고 발주항목',
            );
            this.selectRow(-1);
          })
          .catch(() => {
            this.openOneButtonModal(
              '등록 실패',
              '등록 중 오류가 발생하였습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (!this.checkTotalCostValid) {
        this.openOneButtonModal(
          '지출액 초과',
          '1회 입고 최대 지출액은<br />20억 이상 지출할 수 없습니다.',
        );
        return;
      } else if (!this.checkQuantityZero) {
        this.my_isModalOpen = false;
        this.openOneButtonModal(
          '입고 불가',
          '입고수량은 최소 1개 이상이어야 합니다.',
        );
        return;
      }

      // this.my_isModalOpen = false;
    },

    getMaterialInfo(id) {
      let hit = this.materials.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    getStoreInfo(id) {
      return this.stores.find(x => x.id == id);
    },
    // getSaleCost(index) {
    //   let purchase_cost = 0;
    //   this.purchases[index].material_list.forEach(el => {
    //     purchase_cost += el.total_cost;
    //   });

    //   return makeComma(purchase_cost);
    // },

    async selectRow(index) {
      this.$store.commit('setMaterialSelectedIndexToInput', index);
      const date = new Date();

      this.$store.commit('setMaterialBaseInputDateToInput', yyyymmdd(date));

      if (index != -1) {
        await this.$store.commit(
          'setMaterialManagementDataToInput',
          this.lodash.clonedeep(this.filteredPurchaseMaterialList[index]),
        );

        this.managementData.store_id = this.getMaterialInfo(
          this.managementData.material_id,
        ).default_store_id;
        console.log(this.managementData.store_id);
        this.managementData.location_id = null;
        if (this.managementData.store_id != null) {
          const options = this.getStoreLocationOptions(
            this.managementData.store_id,
          );
          this.managementData.location_options = options;
        }

        this.show_store_selectric = true;
      } else {
        this.$store.commit('setMaterialManagementDataToInput', []);
      }
    },
  },
  async created() {
    if (this.input_date == null) {
      const date = new Date();
      this.$store.commit('setMaterialBaseInputDateToInput', yyyymmdd(date));
    }
    if (
      this.order_purchases_type == undefined ||
      this.order_purchases_type.length < 1
    ) {
      await this.FETCH('FETCH_PURCHASE_TYPE', '구매 유형');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.stores.length == 0) {
      await this.FETCH('FETCH_STORE');
    }
    await this.FETCH('FETCH_PURCHASE_MATERIAL_PURCHASE', '미입고 발주항목');

    if (
      this.managementData.id != undefined &&
      this.purchase_material_purchase.find(
        x => x.id == this.managementData.id,
      ) == undefined
    ) {
      this.selectRow(-1);
    } else if (this.managementData != {}) {
      const findSelectIndex = this.lodash
        .clonedeep(this.filteredPurchaseMaterialList)
        .findIndex(x => x.id == this.managementData.id);

      this.selectRow(findSelectIndex);
    }

    this.show_store_selectric = true;
  },
};
</script>

<style lang="scss" scoped></style>
