<template>
  <div class="modal_body material_info_body">
    <h3>3.입고 정보 확인 / 입고처리</h3>
    <div class="input_text">
      <label>구매처</label>
      <input
        type="text"
        :value="findInfoFromId(companys, company_id).name"
        readonly
      />
    </div>
    <div class="input_text">
      <label>입고일자</label>
      <input
        type="date"
        :value="input_date"
        @change="input_date = $event.target.value"
      />
    </div>
    <div class="mes_tbl_wrap">
      <table class="mes_tbl">
        <thead>
          <tr>
            <th>NO</th>
            <th>원자재명</th>
            <th>규격</th>
            <th>입고수량</th>
            <th>단가</th>
            <th>공급가</th>
            <th>부가세</th>
            <th>합계액</th>
            <th>입고창고</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(m, index) in materialList" :key="index">
            <td>{{ index + 1 }}</td>
            <td class="text_left">
              {{ findInfoFromId(materials, m.material_id).name }}
            </td>
            <td class="text_left">
              {{ findInfoFromId(materials, m.material_id).standard }}
            </td>
            <td class="text_right">
              <b>{{ $makeComma(m.quantity) }}</b>
              {{ findInfoFromId(units, m.unit_id).name }}
            </td>
            <td class="text_right">{{ '₩ ' + $makeComma(m.cost) }}</td>
            <td class="text_right">{{ '₩ ' + $makeComma(m.supply_value) }}</td>
            <td class="text_right">{{ '₩ ' + $makeComma(m.tax) }}</td>
            <td class="text_right">{{ '₩ ' + $makeComma(m.total_cost) }}</td>
            <td>
              {{
                m.store_id != null
                  ? findInfoFromId(stores, m.store_id).name
                  : '미지정'
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import PriceMixin from '@/mixins/material_price';
import { yyyymmdd } from '@/utils/func';
export default {
  mixins: [FETCH_MIXIN, PriceMixin],
  data() {
    return {
      input_date: yyyymmdd(new Date()),
    };
  },
  props: {
    company_id: {
      type: Number,
      require: true,
    },
    material_list: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      stores: 'getStores',
    }),
    materialList() {
      const company_info = this.findInfoFromId(this.companys, this.company_id);
      let materialList = this.lodash.clonedeep(this.material_list);
      return materialList.map(x => ({
        ...x,
        supply_value: this.calSupplyTax(
          company_info.vat,
          x.tax_id,
          x.quantity,
          x.cost,
        ).supply,
        tax: this.calSupplyTax(company_info.vat, x.tax_id, x.quantity, x.cost)
          .tax,
        total_cost: this.calSupplyTax(
          company_info.vat,
          x.tax_id,
          x.quantity,
          x.cost,
        ).total,
      }));
    },
  },
  watch: {
    input_date(newValue) {
      this.$emit('onChange', newValue);
    },
  },
};
</script>

<style scoped></style>
