<template>
  <div class="modal_body select_new_material_body">
    <h3>입고 원자재 선택</h3>
    <div class="input_wrap">
      <div class="input_text">
        <input
          class="input"
          id="search_text2"
          :value="input"
          @input="onInputChange"
          inputmode="none"
          @blur="dofocus()"
        />
      </div>
      <p class="warning">바코드를 스캔하거나 자재명을 검색하십시오</p>
    </div>
    <div class="mes_tbl_wrap">
      <table class="mes_tbl">
        <thead>
          <tr>
            <th>원자재명</th>
            <th>규격</th>
            <th>바코드번호</th>
            <th>선택</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredMaterials" :key="item.id">
            <td class="text_left">
              {{ item.name }}
            </td>
            <td class="text_left">
              {{ item.standard }}
            </td>
            <td>
              {{ item.barcode }}
            </td>
            <td>
              <div class="input_checkbox">
                <label
                  class="chk_box"
                  :for="`checkbox${index}`"
                  :class="{
                    active: selectedMaterials.find(x => x.id == item.id),
                  }"
                  @click="selectMaterial(item)"
                >
                  <i class="fa fa-check"></i>
                </label>
                <input type="checkbox" :id="`checkbox${index}`" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <simple-keyboard v-if="stepIndex == 5" @onChange="onChange" :input="input">
    </simple-keyboard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleKeyboard from '@/layouts/components/SimpleKeyboard.vue';
export default {
  props: {
    new_material_list: {
      type: Array,
    },
    stepIndex: {
      type: Number,
    },
  },
  data() {
    return {
      input: '',
      selectedMaterials: [],
    };
  },
  components: {
    SimpleKeyboard,
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      stores: 'getStores',
    }),
    filteredMaterials() {
      if (this.materials.length < 1) {
        return [];
      } else {
        const Hangul = require('hangul-js');
        const filteredArr = this.materials.filter(
          element =>
            element.barcode == this.input ||
            element.name.includes(this.input.trim()) ||
            Hangul.d(element.name, true)
              .map(x => x[0])
              .join('')
              .includes(this.input.trim()),
        );
        return filteredArr;
      }
    },
  },
  mounted() {
    this.dofocus();
  },
  methods: {
    selectMaterial(item) {
      if (item.total_cost >= 2100000000) {
        this.openOneButtonModal(
          '등록 불가',
          '1회 구매액은 21억을 넘을 수 없습니다.',
        );
        return;
      }
      const includeIndex = this.selectedMaterials.findIndex(
        x => x.id == item.id,
      );
      if (includeIndex != -1) {
        this.selectedMaterials.splice(includeIndex, 1);
      } else {
        this.selectedMaterials.push(this.lodash.clonedeep(item));
      }
    },
    dofocus() {
      $('#search_text2').focus();
    },
    onInputChange(input) {
      this.input = input.target.value;
    },
    onChange(input) {
      this.input = input;
    },
  },
  watch: {
    selectedMaterials(newValue) {
      if (newValue != null) {
        console.log(newValue);
        this.$emit('onChange', this.selectedMaterials);
      } else {
        this.$emit('onChange', null);
      }
    },
  },
  created() {
    if (this.new_material_list.length > 0) {
      this.selectedMaterials = this.new_material_list;
    } else {
      this.selectedMaterials = [];
    }
    $('#search_text2').focus();
    this.dofocus();
  },
};
</script>

<style scoped></style>
