<template>
  <div class="article">
    <transition name="slide-fade-1">
      <div class="receiving tbl_wrap" key="tbl" v-if="selectedIndex == -1">
        <div class="tbl_option">
          <h5>구매처 기준 입고처리</h5>
        </div>
        <h6>조회수 : {{ filteredPurchaseList.length }}건</h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 5" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>구매처</th>
                <th>원자재</th>
                <th>지출액</th>
                <th>발주일자</th>
                <th>입고예정일</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(purchase, index) in filteredPurchaseList"
                :key="purchase.id"
                @click="selectRow(index)"
                :class="{ active: selectedIndex == index }"
              >
                <td class="text_left">
                  {{ getCompanyInfo(purchase.company_id).name }}
                </td>
                <td class="text_left">{{ getFirstPurchaseMaterial(index) }}</td>
                <td class="text_right">&#8361; {{ getSaleCost(index) }}</td>
                <td>{{ purchase.input_date | formatDateNoHours }}</td>
                <td>{{ purchase.incoming_date | formatDateNoHours }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="aside_management_mode" key="aside" v-if="selectedIndex != -1">
        <div class="head">
          <button type="button" class="btn_prev" @click="selectRow(-1)">
            <h5>구매처 기준 입고처리</h5>
          </button>
          <div class="input_text">
            <label>구매처명</label>
            <input
              id="name"
              readonly
              type="text"
              placeholder=""
              :value="getCompanyInfo(managementData.company_id).name"
            />
          </div>
          <div class="input_text">
            <label>입고일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="input_date"
              @change="setInputDate($event)"
              :readonly="!isPermissionOn"
            />
          </div>
        </div>
        <div class="aside_cont">
          <span>미입고 원자재 정보</span>
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <colgroup>
                <col v-for="(n, index) in 10" :key="index" />
              </colgroup>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>원자재</th>
                  <th>규격</th>
                  <th>입고예정일</th>
                  <th>입고창고</th>
                  <th>상세위치</th>
                  <th>수량</th>
                  <th>단가</th>
                  <th>과세 여부</th>
                  <th>지출액</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(material, index) in managementData != undefined
                    ? material_lists
                    : []"
                  :key="material.id"
                >
                  <td>{{ index + 1 }}</td>
                  <td class="text_left">
                    {{ getMaterialInfo(material.material_id).name }}
                  </td>
                  <td class="text_left">
                    {{ getMaterialInfo(material.material_id).standard }}
                  </td>
                  <td>
                    {{ managementData.incoming_date | formatDateNoHours }}
                  </td>
                  <td>
                    <my-selectric
                      v-if="show_store_selectric"
                      :id="`store_selectric`"
                      :watch="material.store_id"
                      :options="store_options"
                      :state="!isPermissionOn ? 'disabled' : ''"
                      :index="index"
                      :commit="'setCompanyManagementStoreIdToInput'"
                      :key="reRend"
                      @refresh="changeKey($event)"
                    >
                    </my-selectric>
                  </td>
                  <td>
                    <my-selectric
                      v-if="material.store_id != null"
                      :id="`location_selectric`"
                      :watch="material.location_id"
                      :options="material.location_options"
                      :state="
                        selectedIndex == -1 || !isPermissionOn ? 'disabled' : ''
                      "
                      :index="index"
                      :commit="'setCompanyManagementLocationIdToInput'"
                      :key="reRend2"
                      @refresh="changeKey2($event)"
                    >
                    </my-selectric>
                  </td>
                  <td>
                    <input
                      type="text"
                      :value="$makeComma(material.quantity)"
                      inputmode="decimal"
                      @input="$inputNumber($event, material, 'quantity')"
                      :disabled="!isPermissionOn"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      :value="$makeComma(material.cost)"
                      inputmode="decimal"
                      @input="$inputNumberInt($event, material, 'cost')"
                      :disabled="!isPermissionOn"
                    />
                  </td>
                  <td>{{ material.tax_id ? 'Y' : 'N' }}</td>
                  <td>
                    <input
                      type="text"
                      :value="
                        $makeComma(
                          calSupplyTax(
                            managementData.vat_id,
                            material.tax_id,
                            $makeNumber(material.quantity),
                            $makeNumber(material.cost),
                          ).total,
                        )
                      "
                      inputmode="decimal"
                      @blur="
                        $event =>
                          ($event.target.value = $makeComma(
                            calSupplyTax(
                              managementData.vat_id,
                              material.tax_id,
                              $makeNumber(material.quantity),
                              $makeNumber(material.cost),
                            ).total,
                          ))
                      "
                      @keydown.enter="
                        $event => reCalCost(material, $event.target.value)
                      "
                      @keypress="onlyNumber($event)"
                      @input="$inputNumberIntNoTarget($event)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="receiving_info" @submit.prevent>
            <div class="payment">
              <div class="input_text">
                <label>부가세액</label>
                <input
                  type="text"
                  placeholder=""
                  readonly
                  :value="`₩ ${$makeComma(makeTax)}`"
                />
              </div>
              <div class="input_text">
                <label>과세총액</label>
                <input
                  type="text"
                  placeholder=""
                  readonly
                  :value="
                    managementData.id != undefined
                      ? '₩ ' +
                        calTotalPrice(
                          material_lists,
                          managementData.vat_id,
                          false,
                        ).tax_total_cost
                      : ''
                  "
                />
              </div>
              <div class="input_text">
                <label>비과세총액</label>
                <input
                  type="text"
                  placeholder=""
                  readonly
                  :value="
                    managementData.id != undefined
                      ? '₩ ' +
                        calTotalPrice(
                          material_lists,
                          managementData.vat_id,
                          false,
                        ).non_tax_total_cost
                      : ''
                  "
                />
              </div>
              <div class="input_text">
                <label>총 지출액</label>
                <input
                  type="text"
                  placeholder=""
                  readonly
                  :value="
                    managementData.id != undefined
                      ? '₩ ' +
                        calTotalPrice(
                          material_lists,
                          managementData.vat_id,
                          false,
                        ).total_cost
                      : ''
                  "
                />
              </div>
            </div>
            <button
              class="btn_sub2"
              @click="ShowModal"
              v-if="selectedIndex != -1 && isPermissionOn"
            >
              입고 처리
            </button>
          </div>
        </div>
      </div>
    </transition>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="submitForm"
    ></two-button-modal>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import PriceMixin from '@/mixins/material_price';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import MySelectric from '@/layouts/components/MySelectric.vue';
import { mapGetters } from 'vuex';
import { makeComma } from '@/utils/filters';
import FavoriteMixin from '@/mixins/favorite';
import { yyyymmdd } from '@/utils/func';
export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin, PriceMixin, FavoriteMixin],
  components: {
    TwoButtonModal,
    MySelectric,
  },
  computed: {
    ...mapGetters({
      order_purchases_type: 'getOrderPurchaseType',
      draft_purchases_type: 'getDraftPurchaseType',
      purchases: 'getOrderPurchaseOnlyInputNotCompleted',
      selectedIndex: 'getCompanySelectedIndexFromInput',
      managementData: 'getCompanyManagementDataFromInput',
      material_lists: 'getCompanyManagementDataMaterialListFromInput',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      input_date: 'getInputDateFromInput',
    }),
    filteredPurchaseList() {
      if (this.purchases.length > 0) {
        return this.lodash
          .clonedeep(this.purchases)
          .sort(
            (a, b) =>
              new Date(b.input_date) - new Date(a.input_date) || b.id - a.id,
          );
      } else return [];
    },
    checkTotalCostValid() {
      if (this.selectedIndex != -1) {
        const calPrice = this.$makeNumber(
          this.calTotalPrice(
            this.lodash.clonedeep(this.material_lists),
            this.managementData.vat_id,
            false,
          ).total_cost,
        );
        if (calPrice <= 2147483647) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    checkQuantityZero() {
      if (this.selectedIndex != -1) {
        let modifyMaterialListLen = this.managementData.material_list.length;
        let chkData = this.lodash.clonedeep(this.managementData.material_list);
        let chkZero = false;

        for (let i = 0; i < modifyMaterialListLen; i++) {
          if (this.$makeNumber(chkData[i].quantity) == 0) {
            chkZero = true;
          }
        }

        if (chkZero) {
          return false;
        } else return true;
      } else return false;
    },
    makeTax() {
      return this.material_lists
        .map(
          x =>
            this.calSupplyTax(
              this.managementData.vat_id,
              x.tax_id,
              x.quantity,
              x.cost,
            ).tax,
        )
        .reduce((a, b) => this.$decimalAdd(a, b), 0);
    },
  },
  data() {
    return {
      show_store_selectric: false,
      reRend: 0,
      reRend2: 0,
      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  methods: {
    reCalCost(row, newPurchaseValue) {
      const total_value = this.$makeNumber(newPurchaseValue);
      console.log('total_value', total_value);
      const company_vat = this.companys.find(
        x => x.id == this.managementData.company_id,
      ).vat;
      const product_tax = row.tax_id;
      let rowQuantity = this.$makeNumber(row.quantity);
      if (rowQuantity === 0) {
        row.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
    },
    barcodeInput(arg) {
      const purchase_id = arg.substr(-5);
      const index = this.filteredPurchaseList.findIndex(
        x => x.id == Number(purchase_id),
      );
      if (index != -1) {
        this.selectRow(index);
        this.ShowModal();
      } else {
        this.openOneButtonModal(
          '입고실패',
          '입고 예정인 발주서가 검색되지 않습니다.',
        );
      }
    },
    changeKey(arg) {
      console.log('changeKey', this.managementData);
      this.reRend += 1;
      const options = this.getStoreLocationOptions(arg.value);
      this.material_lists[arg.index].location_options = options;
      this.material_lists[arg.index].location_id = null;
    },
    changeKey2() {
      this.reRend2 += 1;
    },
    setInputDate(e) {
      if (e.target.value != '') {
        this.$store.commit('setInputDateToInput', e.target.value);
      } else {
        e.target.value = this.input_date;
        this.openOneButtonModal(
          '주의',
          '입고일을 삭제할 수 없습니다.<br/> 다른 날짜를 선택해주세요.',
        );
        this.$store.commit('setInputDateToInput', yyyymmdd(new Date()));
      }
    },
    getStoreLocationOptions(id) {
      // console.log(this.material_lists[id].store_id);
      const storeInfo = this.getStoreInfo(id);

      let options = [];
      options.push({ label: '선택', value: null, detail: '' });

      if (storeInfo != undefined) {
        console.log(storeInfo);
        const types = storeInfo.locations.sort((a, b) => a.id - b.id);
        types.forEach(el => {
          options.push({ label: el.name, value: el.id, detail: el.detail });
        });
      }
      return options;
    },
    ShowModal() {
      this.my_modal_title = '입고처리';
      this.my_modal_content =
        '발주항목을 일괄 입고처리하시겠습니까?<br /><br />※수정된 내용은 발주서에 동시 반영됩니다.';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_isModalOpen = false;
    },
    async submitForm() {
      if (this.checkTotalCostValid && this.checkQuantityZero) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.completed = true;

        let material_control = [];
        let sumSupplyMoney = 0;
        let sumTaxMoney = 0;
        let sumTotalMoney = 0;
        const material_lists = this.lodash.clonedeep(this.material_lists);
        material_lists.forEach(el => {
          el.input_date = this.lodash.clonedeep(this.input_date);
          el.cost = this.$makeNumber(el.cost);
          el.quantity = this.$makeNumber(el.quantity);
          const recal_costs = this.lodash.clonedeep(
            this.calSupplyTax(payload.vat_id, el.tax_id, el.quantity, el.cost),
          );
          el.supply_value = recal_costs.supply;
          el.tax = recal_costs.tax;
          el.total_cost = recal_costs.total;

          const elTemp = {
            input_date: this.lodash.clonedeep(this.input_date),
            quantity: el.quantity,
            material_ident: null,
            material_id: el.material_id,
            store_id: el.store_id,
            location_id: el.location_id,
            purchase_material_id: el.id,
          };

          sumSupplyMoney += el.supply_value;
          sumTaxMoney += el.tax;
          sumTotalMoney += el.total_cost;

          material_control.push(elTemp);
        });
        const purchase_account = {
          supply_value: sumSupplyMoney,
          tax: sumTaxMoney,
          total_value: sumTotalMoney,
          purchase_id: payload.id,
          input_date: this.lodash.clonedeep(this.input_date),
        };
        payload.purchase_materials = this.lodash.clonedeep(
          material_lists.filter(x => x.input_yn == false),
        );
        payload.purchase_account = purchase_account;

        this.showSpinner();
        this.$store
          .dispatch('UPDATE_PURCHASE_ALL_UPDATE', payload)
          .then(() => {
            this.$store
              .dispatch('INSERT_MATERIAL_CONTROL_N_IN_OUT', {
                data_list: material_control,
              })
              .then(() => {
                this.openOneButtonModal(
                  '입고 성공',
                  '입고처리가 완료되었습니다.',
                );
                this.FETCH_PURCHASE();
                this.FETCH(
                  'FETCH_PURCHASE_MATERIAL_PURCHASE',
                  '미입고 발주항목',
                );

                this.selectRow(-1);
              })
              .catch(error => {
                console.log(error);
                this.openOneButtonModal(
                  '등록 실패',
                  '입고등록 중 오류가 발생했습니다.',
                );
              });
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '등록 실패',
              '입고등록 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
        this.my_isModalOpen = false;
      } else if (!this.checkTotalCostValid) {
        this.my_isModalOpen = false;
        this.openOneButtonModal(
          '총 지출액 초과',
          '1회 입고 최대 지출액은<br />20억 이상 지출할 수 없습니다.',
        );
        return;
      } else if (!this.checkQuantityZero) {
        this.my_isModalOpen = false;
        this.openOneButtonModal(
          '입고 불가',
          '입고수량은 최소 1개 이상이어야 합니다.',
        );
        return;
      }
    },
    async FETCH_PURCHASE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_PURCHASE', {
          start_date: '1950-01-01',
          end_date: '2500-12-30',
          check_only_not_completed: false,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '발주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    getMaterialInfo(id) {
      let hit = this.materials.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    getFirstPurchaseMaterial(index) {
      const firstMaterial = this.getMaterialInfo(
        this.filteredPurchaseList[index].material_list.filter(
          x => x.input_yn == false,
        )[0].material_id,
      ).name;
      return this.filteredPurchaseList[index].material_list.filter(
        x => x.input_yn == false,
      ).length > 1
        ? firstMaterial +
            ' 외 ' +
            Number(
              this.filteredPurchaseList[index].material_list.filter(
                x => x.input_yn == false,
              ).length - 1,
            ) +
            '개 원자재'
        : firstMaterial;
    },
    getSaleCost(index) {
      let purchase_cost = 0;
      this.filteredPurchaseList[index].material_list
        .filter(x => x.input_yn == false)
        .forEach(el => {
          purchase_cost += el.total_cost;
        });

      return makeComma(purchase_cost);
    },

    getStoreInfo(id) {
      return this.stores.find(x => x.id == id);
    },
    async selectRow(index) {
      const date = new Date();
      this.$store.commit('setInputDateToInput', yyyymmdd(date));
      this.$store.commit('setCompanySelectedIndexToInput', index);
      this.show_store_selectric = false;
      if (index != -1) {
        await this.$store.commit(
          'setCompanyManagementDataToInput',
          this.lodash.clonedeep(this.filteredPurchaseList[index]),
        );
        this.material_lists.forEach(el => {
          el.store_id = this.getMaterialInfo(el.material_id).default_store_id;
          console.log(el.store_id);
          el.location_id = null;
          if (el.store_id != null) {
            const options = this.getStoreLocationOptions(el.store_id);
            el.location_options = options;
          }
        });
        this.show_store_selectric = true;
      } else {
        this.$store.commit('setCompanyManagementDataToInput', []);
      }
    },
  },
  async created() {
    if (
      this.order_purchases_type == undefined ||
      this.order_purchases_type.length < 1
    ) {
      await this.FETCH('FETCH_PURCHASE_TYPE', '구매 유형');
    }

    await this.FETCH_PURCHASE();

    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.stores.length == 0) {
      await this.FETCH('FETCH_STORE');
    }
    if (this.input_date == null) {
      const date = new Date();
      this.$store.commit('setInputDateToInput', yyyymmdd(date));
    }

    if (
      this.managementData.id != undefined &&
      this.purchases.find(x => x.id == this.managementData.id) == undefined
    ) {
      this.selectRow(-1);
    }

    this.show_store_selectric = true;
  },
};
</script>

<style lang="scss" scoped>
/* 애니메이션 진입 및 진출은 다른 지속 시간 및  */
/* 타이밍 기능을 사용할 수 있습니다. */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.55s ease-in-out;
}
.slide-fade-enter {
  transform: translateX(100%);
}
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>
