<template>
  <div
    id="contents"
    :class="
      tabIndex == 0
        ? 'receiving_register'
        : tabIndex == 1
        ? 'raw_material_register'
        : 'purchase_management'
    "
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToInputPage'"
          :options="[
            { title: '구매처 기준 입고', name: 'company_input' },
            { title: '원자재 기준 입고', name: 'material_input' },
            { title: '입고식별표로 입고', name: 'input_barcode_input' },
            { title: '간편 입고 등록', name: 'simple_input_regist' },
            { title: '구매 조회 및 관리', name: 'input_management' },
          ]"
          @showBarcodeSearch="showBarcodeSearch = true"
          @showSimpleInputModal="showSimpleInputModal = true"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            class="btn_admin"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-show="isPermissionOn && tabIndex == 4"
            @click="toggleManagement"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="tabIndex == 0 ? 'active' : ''">
            <a @click="SetOpenTabIndex(0)">구매처 기준 입고</a>
          </li>
          <li
            :class="tabIndex == 1 ? 'active' : ''"
            v-if="systemCompany.id != 17"
          >
            <a @click="SetOpenTabIndex(1)">원자재 기준 입고</a>
          </li>
          <li :class="tabIndex == 2 ? 'active' : ''" v-if="isPermissionOn">
            <a @click.prevent="showBarcodeSearch = true">입고식별표로 입고</a>
          </li>
          <li :class="tabIndex == 3 ? 'active' : ''" v-if="isPermissionOn">
            <a @click.prevent="showSimpleInputModal = true">간편 입고 등록</a>
          </li>
          <li :class="tabIndex == 4 ? 'active' : ''">
            <a @click="SetOpenTabIndex(4)">구매 조회 및 관리</a>
          </li>
        </ul>
      </div>
      <!--  -->
      <company-base-form v-if="tabIndex == 0"></company-base-form>
      <material-base-form v-if="tabIndex == 1"></material-base-form>
      <input-management-form v-if="tabIndex == 4"></input-management-form>
    </div>
    <barcode-material
      v-if="showBarcodeSearch"
      @onclose="showBarcodeSearch = false"
    >
    </barcode-material>
    <simple-input-modal
      v-if="showSimpleInputModal"
      @onclose="showSimpleInputModal = false"
    ></simple-input-modal>
  </div>
</template>

<script>
import CompanyBaseForm from '@/views/forms/Material/Input/CompanyBaseForm';
import MaterialBaseForm from '@/views/forms/Material/Input/MaterialBaseForm';
import InputManagementForm from '@/views/forms/Material/Input/InputManagementForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import BarcodeMaterial from '@/layouts/components/BarcodeMaterialModal';
import { mapGetters, mapMutations } from 'vuex';
import SimpleInputModal from '@/layouts/components/SimpleInputModalComponents/SimpleInputModal.vue';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  data() {
    return {
      showBarcodeSearch: false,
      showSimpleInputModal: false,
    };
  },
  components: {
    CompanyBaseForm,
    MaterialBaseForm,
    InputManagementForm,
    AsideSelectric,
    BarcodeMaterial,
    SimpleInputModal,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromInputPage',
      // selectedIndex: 'getTableSelectedIndexFromInputPage',
      managementMode: 'getManagementModeFromInputPage',
      systemCompany: 'getSystemCompany',
    }),
    menuFilter() {
      let meunOption = [];

      if (Number(localStorage.getItem('saupKey')) == 17) {
        meunOption = [
          { title: '구매처 기준 입고', name: 'company_input' },
          { title: '입고식별표로 입고', name: 'input_barcode_input' },
          { title: '간편 입고 등록', name: 'simple_input_regist' },
          { title: '구매 조회 및 관리', name: 'input_management' },
        ];
      } else {
        meunOption = [
          { title: '구매처 기준 입고', name: 'company_input' },
          { title: '원자재 기준 입고', name: 'material_input' },
          { title: '입고식별표로 입고', name: 'input_barcode_input' },
          { title: '간편 입고 등록', name: 'simple_input_regist' },
          { title: '구매 조회 및 관리', name: 'input_management' },
        ];
      }
      return meunOption;
    },
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToInputPage',
      toggleManagementMode: 'toggleManagementModeToInputPage',
    }),
    toggleManagement() {
      if (this.tabIndex == 4) {
        this.toggleManagementMode();
      }
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitInputPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
