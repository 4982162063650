import { render, staticRenderFns } from "./SelectMaterial.vue?vue&type=template&id=732b4cff&scoped=true&"
import script from "./SelectMaterial.vue?vue&type=script&lang=js&"
export * from "./SelectMaterial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "732b4cff",
  null
  
)

export default component.exports