<template>
  <div class="modal_body select_material_body">
    <h3>2.입고할 자재를 입력하여주십시오</h3>
    <div class="mes_tbl_wrap">
      <table class="mes_tbl">
        <thead>
          <tr>
            <th>NO</th>
            <th>원자재명</th>
            <th>규격</th>
            <th>입고수량</th>
            <th>단가</th>
            <th>입고창고</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(m, index) in material_list" :key="index">
            <td>{{ index + 1 }}</td>
            <td class="text_left">
              {{ findInfoFromId(materials, m.material_id).name }}
            </td>
            <td class="text_left">
              {{ findInfoFromId(materials, m.material_id).standard }}
            </td>
            <td class="text_right">
              <input
                type="text"
                min="0"
                inputmode="decimal"
                @input="$inputNumber($event, m, 'quantity')"
                :value="$makeComma(m.quantity)"
              />
              <p>{{ findInfoFromId(units, m.unit_id).name }}</p>
            </td>
            <td class="text_right">
              <input
                type="text"
                min="0"
                inputmode="decimal"
                @input="$inputNumber($event, m, 'cost')"
                :value="$makeComma(m.cost)"
              />
            </td>
            <td>
              <my-local-selectric
                :id="`store_selectric`"
                :watch="m.store_id"
                :options="store_options"
                @changeValue="m.store_id = $event"
              >
              </my-local-selectric>
            </td>
            <td>
              <button
                class="tbl_delete_btn"
                @click="material_list.splice(index, 1)"
              >
                delete
              </button>
            </td>
          </tr>
          <tr class="new">
            <td colspan="7" @click="$emit('new')">
              신규 원자재 추가
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric.vue';
export default {
  mixins: [FETCH_MIXIN],
  props: {
    company_id: {
      type: Number,
      require: true,
    },
    material_list: {
      type: Array,
    },
  },
  components: {
    MyLocalSelectric,
  },
  data() {
    return {
      input: '',
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      stores: 'getStores',
      store_options: 'getStoresForSelectric',
    }),
  },
  watch: {
    material_list(newValue) {
      if (newValue != null) {
        this.$emit('onChange', this.material_list);
      } else {
        this.$emit('onChange', null);
      }
    },
  },
  methods: {
    focus() {
      $('#search_text').focus();
    },
    onChange(input) {
      this.input = input;
    },
  },
};
</script>

<style scoped></style>
