<template>
  <div>
    <div class="modalPopup material_modal barcode_modal">
      <div class="modal_header">
        <h3 class="title">
          입고식별표로 입고하기
        </h3>
        <button
          type="button"
          class="modal_close"
          @click="isModalClose"
        ></button>
      </div>
      <div class="modal_body material_body">
        <div class="input_wrap">
          <div class="input_text">
            <label>식별표 No.</label>
            <div class="barcode_img"></div>
          </div>
          <div class="input_text">
            <input
              type="text"
              ref="focusMe"
              placeholder="식별표 번호를 입력하세요."
              @input="pushToBarcodeList($event)"
            />
          </div>
          <p class="warning">
            입고식별표에 기입된 바코드 번호를 입력하거나 바코드리더기로
            스캔하여주십시오.
          </p>
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>구매처명</th>
                <th>원자재명</th>
                <th>수량</th>
                <th>단위</th>
                <th>수입검사여부</th>
                <th>발주일자</th>
                <th>입고예정일</th>
                <th>바코드번호</th>

                <th v-if="barcode_input_list.length > 0">삭제</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in barcode_input_list" :key="item.id">
                <td class="text_left">
                  {{ getCompanyInfo(item.company_id).name }}
                </td>
                <td class="text_left">
                  {{ getMaterialInfo(item.material_id).name }}
                </td>
                <td class="text_right">
                  <input
                    type="text"
                    inputmode="decimal"
                    :value="$makeComma(item.quantity)"
                    @input="$inputNumber($event, item, 'quantity')"
                  />
                </td>
                <td>
                  {{ getUnitName(item.unit_id) }}
                </td>
                <td>
                  {{
                    getMaterialInfo(item.material_id).inspection
                      ? '검사'
                      : '생략'
                  }}
                </td>
                <td>{{ item.input_date | formatDateNoHours }}</td>
                <td>{{ item.incoming_date | formatDateNoHours }}</td>
                <td>
                  {{ item.barcode_num }}
                </td>

                <td
                  v-if="barcode_input_list.length > 0"
                  @click="barcode_input_list.splice(index, 1)"
                >
                  <button class="tbl_delete_btn">
                    delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <button
          type="button"
          class="btn_sub2"
          @click="submitForm()"
          v-if="barcode_input_list.length > 0"
        >
          입고 처리
        </button>
      </div>
    </div>
    <div class="modal_layer"></div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import SpinnerMixin from '@/mixins/spinner';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import PriceMixin from '@/mixins/material_price';

export default {
  mixins: [FETCH_MIXIN, ModalMixin, SpinnerMixin, PriceMixin, DRAG_MODAL_MIXIN],
  data() {
    return {
      barcode_input_list: [],
    };
  },
  computed: {
    ...mapGetters({
      purchases: 'getOrderPurchaseOnlyInputNotCompleted',
      selectedIndex: 'getMaterialSelectedIndexFromInput',
      purchase_material_purchase_src: 'getPurchaseMaterialPurchaseOnlyNotInput',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      stores: 'getStores',
      input_date: 'getMaterialBaseInputDateFromInput',
      start_date: 'getInputStartDateFromInput',
      end_date: 'getInputEndDateFromInput',
    }),
    purchase_material_purchase() {
      if (this.purchase_material_purchase_src.length < 1) {
        return [];
      } else {
        return this.lodash
          .clonedeep(this.purchase_material_purchase_src)
          .map(x => {
            x.barcode_num =
              x.input_date.replaceAll('-', '') +
              ('00000' + String(x.id)).substr(-5);
            return x;
          });
      }
    },
  },
  mounted() {
    this.$nextTick(() => this.$refs.focusMe.focus());
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    pushToBarcodeList(e) {
      const barcode = e.target.value.trim();
      if (barcode.length == 13) {
        const barcode_id = Number(barcode.substr(-5));
        const purchase_material = this.purchase_material_purchase.find(
          x => x.id == barcode_id,
        );
        if (purchase_material == undefined) {
          this.openOneButtonModal(
            '등록 실패',
            '올바르지 않은 바코드거나 이미 입고처리된 식별표입니다.',
          );
        } else if (this.barcode_input_list.find(x => x.id == barcode_id)) {
          this.openOneButtonModal(
            '등록 실패',
            '이미 입고항목에 추가된 식별표입니다.',
          );
        } else {
          this.barcode_input_list.push(
            this.lodash.clonedeep(purchase_material),
          );
        }
        e.target.value = '';
      }
    },
    /**
     * purchase_material 의 배열을 다시 purchase 안으로 그룹핑하여 집어넣음
     * @param {Array} origin purchase_material 의 배열
     * @returns {{
     * company_id : number,
     * completed : boolean,
     * create_time : string,
     * detail : string,
     * id : number,
     * incoming_date : string,
     * input_date : string,
     * material_list : Array,
     * purchase_ident : string,
     * purchase_type_id : number,
     * vat_id : number0
     * }[]} material_list 배열을 포함한 purchase의 배열
     */
    groupByPurchaseId(origin) {
      const obj = origin.reduce(function(rv, x) {
        (rv[x['purchase_id']] = rv[x['purchase_id']] || []).push(x);
        return rv;
      }, {});
      let arr = [];
      for (let k in obj) {
        arr.push({
          purchase_id: k,
          material_list: obj[k],
        });
      }
      arr = arr.map(x => {
        const purchase = this.lodash
          .clonedeep(this.purchases)
          .find(y => y.id == x.purchase_id);
        purchase.material_list = x.material_list;
        return purchase;
      });
      return arr;
    },
    async FETCH_PURCHASE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_PURCHASE', {
          start_date: '1950-01-01',
          end_date: '2500-12-30',
          check_only_not_completed: false,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '발주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async submitForm() {
      const payloadList = this.groupByPurchaseId(
        this.lodash.clonedeep(this.barcode_input_list),
      );

      new Promise(async (resolve, reject) => {
        await payloadList.forEach(async payload => {
          let material_control = [];
          let sumSupplyMoney = 0;
          let sumTaxMoney = 0;
          let sumTotalMoney = 0;
          const material_lists = this.lodash.clonedeep(payload.material_list);
          this.showSpinner();
          material_lists.forEach(el => {
            el.input_date = yyyymmdd(new Date());
            el.cost = this.$makeNumber(el.cost);
            el.quantity = this.$makeNumber(el.quantity);
            const recal_costs = this.lodash.clonedeep(
              this.calSupplyTax(
                payload.vat_id,
                el.tax_id,
                el.quantity,
                el.cost,
              ),
            );
            el.supply_value = recal_costs.supply;
            el.tax = recal_costs.tax;
            el.total_cost = recal_costs.total;

            const elTemp = {
              input_date: yyyymmdd(new Date()),
              quantity: el.quantity,
              material_ident: null,
              material_id: el.material_id,
              store_id: this.findInfoFromId(this.materials, el.material_id)
                .default_store_id,
              location_id: null,
              purchase_material_id: el.id,
            };

            sumSupplyMoney += el.supply_value;
            sumTaxMoney += el.tax;
            sumTotalMoney += el.total_cost;

            material_control.push(elTemp);
          });
          const purchase_account = {
            supply_value: sumSupplyMoney,
            tax: sumTaxMoney,
            total_value: sumTotalMoney,
            purchase_id: payload.id,
            input_date: yyyymmdd(new Date()),
            // input_date: '123142', 에러내기
          };
          payload.purchase_materials = this.lodash.clonedeep(
            material_lists.filter(x => x.input_yn == false),
          );
          payload.purchase_account = purchase_account;

          console.log('payload 확인', payload);
          console.log({
            data_list: material_control,
          });
          console.log('payload', payload);

          await this.$store
            .dispatch('UPDATE_PURCHASE_ALL_UPDATE', payload)
            .then(() => {
              this.$store
                .dispatch('INSERT_MATERIAL_CONTROL_N_IN_OUT', {
                  data_list: material_control,
                })
                .then(response => {
                  resolve(response);
                })
                .catch(error => {
                  reject(error);
                  return;
                });
            })
            .catch(error => {
              reject(error);
              return;
            });
        });
      })
        .then(async response => {
          console.log('response', response);
          this.openOneButtonModal('입고 성공', '입고처리가 완료되었습니다.');
          await this.FETCH_PURCHASE();
          await this.FETCH(
            'FETCH_PURCHASE_MATERIAL_PURCHASE',
            '미입고 발주항목',
          );
          if (this.start_date != null) {
            await this.FETCH_WITH_PAYLOAD(
              'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
              {
                start: this.start_date,
                end: this.end_date,
              },
              '구매 내역',
            );
          }
          this.$emit('onclose');
        })
        .catch(error => {
          this.openOneButtonModal(
            '등록 실패',
            '입고등록 중 오류가 발생했습니다.',
          );
          console.log('error', error);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    getMaterialInfo(id) {
      let hit = this.materials.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
  },
  async created() {
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.stores.length == 0) {
      await this.FETCH('FETCH_STORE');
    }
    await this.FETCH_PURCHASE();
    await this.FETCH('FETCH_PURCHASE_MATERIAL_PURCHASE', '미입고 발주항목');
  },
};
</script>

<style></style>
