<template>
  <div class="article">
    <div class="receiving tbl_wrap">
      <h5>구매 현황</h5>
      <div class="tbl_option">
        <div class="tbl_opt">
          <span>입고일</span>
          <!-- <div> -->
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                id="start_date"
                :value="start_date"
                @change="setStartDate($event)"
                v-if="showSearchDate"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                id="end_date"
                @change="setEndDate($event)"
                :value="end_date"
                v-if="showSearchDate"
              />
            </div>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ purchase_accounts.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 5" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>입고일</th>
              <th>구매처</th>
              <th>입고 원자재</th>
              <th>지출액</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in purchase_accounts"
              :key="item.id"
              @click="selectRow(index)"
              :class="{ active: selectedIndex == index }"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.create_time | formatDateNoHours }}</td>
              <td class="text_left">
                {{ findInfoFromId(companys, item.purchase.company_id).name }}
              </td>
              <td class="text_left">
                {{
                  `${
                    findInfoFromId(
                      materials,
                      item.purchase_material_list[0].material_id,
                    ).name
                  }${
                    item.purchase_material_list.length > 1
                      ? ` 외 ${item.purchase_material_list.length - 1}개 원자재`
                      : ''
                  }`
                }}
              </td>
              <td class="text_right">
                &#8361; {{ item.total_value | makeComma }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <div class="input_text">
          <label>구매처</label>
          <input
            id="name"
            readonly
            type="text"
            placeholder=""
            :value="
              managementData != undefined
                ? findInfoFromId(companys, managementData.purchase.company_id)
                    .name
                : ''
            "
          />
        </div>
      </div>
      <div class="aside_cont">
        <span>구매 내역</span>
        <div class="mes_tbl_wrap search_tbl purchase_list">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 7" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>원자재</th>
                <th>규격</th>
                <th>수량(단위)</th>
                <th>단가</th>
                <th>과세 여부</th>
                <th>지출액</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(x, index) in managementData != undefined
                  ? managementData.purchase_material_list
                  : []"
                :key="x.id"
              >
                <td>{{ index + 1 }}</td>
                <td class="text_left">
                  {{ findInfoFromId(materials, x.material_id).name }}
                </td>
                <td class="text_left">
                  {{ findInfoFromId(materials, x.material_id).standard }}
                </td>
                <td class="text_right">
                  {{ x.quantity | makeComma
                  }}{{ `(${findInfoFromId(units, x.unit_id).name})` }}
                </td>
                <td v-if="managementMode" class="tbl_sub_info">
                  <input
                    type="text"
                    :value="$makeComma(x.cost)"
                    @input="$inputNumber($event, x, 'cost')"
                    inputmode="decimal"
                    :disabled="!managementMode"
                  />
                </td>
                <td class="text_right" v-else>
                  &#8361; {{ x.cost | makeComma }}
                </td>
                <td>{{ x.tax_id ? '과세' : '면세' }}</td>
                <td v-if="managementMode">
                  <input
                    type="text"
                    :value="
                      $makeComma(
                        calSupplyTax(
                          managementData.purchase.vat_id,
                          x.tax_id,
                          $makeNumber(x.quantity),
                          $makeNumber(x.cost),
                        ).total,
                      )
                    "
                    inputmode="decimal"
                    @blur="
                      $event =>
                        ($event.target.value = $makeComma(
                          calSupplyTax(
                            managementData.purchase.vat_id,
                            x.tax_id,
                            $makeNumber(x.quantity),
                            $makeNumber(x.cost),
                          ).total,
                        ))
                    "
                    @keydown.enter="
                      $event => reCalCostFromTotalValue(x, $event.target.value)
                    "
                    @keypress="onlyNumber($event)"
                    @input="$inputNumberIntNoTarget($event)"
                  />
                </td>
                <td class="text_right" v-else>
                  &#8361; {{ x.total_cost | makeComma }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span>입고내역</span>
        <div class="mes_tbl_wrap search_tbl input_list">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 5" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>원자재명</th>
                <th>규격</th>
                <th>입고수량</th>
                <th>단위</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(y, index) in concatedMaterialInOutList.filter(
                  x =>
                    x.material_in_out_type_id ==
                    this.materialInOutType.find(x => x.detail == 'buy in').id,
                )"
                :key="y.id"
              >
                <td>{{ index + 1 }}</td>
                <td class="text_left">
                  {{
                    findInfoFromId(materials, y.material_control.material_id)
                      .name
                  }}
                </td>
                <td class="text_left">
                  {{
                    findInfoFromId(materials, y.material_control.material_id)
                      .standard
                  }}
                </td>
                <td class="text_right">{{ y.quantity | makeComma }}</td>
                <td>
                  {{
                    findInfoFromId(
                      units,
                      managementData.purchase_material_list.find(
                        x => x.material_id == y.material_control.material_id,
                      ).unit_id,
                    ).name
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="receiving_info" @submit.prevent v-if="selectedIndex != -1">
          <div class="payment">
            <div class="input_text">
              <label>공급가</label>
              <input
                type="text"
                placeholder=""
                readonly
                :value="
                  managementMode
                    ? `₩ ${$makeComma(reCalCost.supply_value)}`
                    : `₩ ${$makeComma(managementData.supply_value)}`
                "
              />
            </div>
            <div class="input_text">
              <label>부가세액</label>
              <input
                type="text"
                placeholder=""
                readonly
                :value="
                  managementMode
                    ? `₩ ${$makeComma(reCalCost.tax)}`
                    : `₩ ${$makeComma(managementData.tax)}`
                "
              />
            </div>
            <div class="input_text">
              <label>합계액</label>
              <input
                type="text"
                placeholder=""
                readonly
                :value="
                  managementMode
                    ? `₩ ${$makeComma(reCalCost.total_value)}`
                    : `₩ ${$makeComma(managementData.total_value)}`
                "
              />
            </div>
          </div>
          <div>
            <button
              class="btn_sub2"
              v-if="managementMode"
              :disabled="isValidModify"
              @click="submitModify()"
            >
              수정
            </button>
            <div class="delete_input" v-if="managementMode">
              <button
                @click="checkValid"
                v-show="selectedIndex != -1 && managementMode"
              >
                <span></span>입고 취소
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="my_isModalOpen = false"
      @OnYesClick="
        () => {
          submitForm();
          my_isModalOpen = false;
        }
      "
    ></two-button-modal>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import PriceMixin from '@/mixins/material_price';
import FavoriteMixin from '@/mixins/favorite';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin, PriceMixin, FavoriteMixin],
  components: {
    TwoButtonModal,
  },
  computed: {
    ...mapGetters({
      order_purchases_type: 'getOrderPurchaseType',
      draft_purchases_type: 'getDraftPurchaseType',
      purchases: 'getOrderPurchaseOnlyInputNotCompleted',
      selectedIndex: 'getInputSelectedIndexFromInput',
      managementData: 'getInputManagementDataFromInput',
      managementMode: 'getManagementModeFromInputPage',
      material_lists: 'getCompanyManagementDataMaterialListFromInput',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      start_date: 'getInputStartDateFromInput',
      date: 'getDateFromInput',
      end_date: 'getInputEndDateFromInput',
      purchase_account: 'getPurchaseAccountFromInput',
      materialInOutType: 'getMaterialInOutType',
      purchaseTypes: 'getPurchaseType',
    }),
    reCalCost() {
      if (this.managementData != undefined) {
        const recal = this.lodash
          .clonedeep(this.managementData.purchase_material_list)
          .map(x => {
            x.recal = this.calSupplyTax(
              this.managementData.purchase.vat_id,
              x.tax_id,
              this.$makeNumber(x.quantity),
              this.$makeNumber(x.cost),
            );
            return x;
          });
        return {
          supply_value: recal
            .map(x => x.recal.supply)
            .reduce((a, b) => a + b, 0),
          tax: recal.map(x => x.recal.tax).reduce((a, b) => a + b, 0),
          total_value: recal.map(x => x.recal.total).reduce((a, b) => a + b, 0),
        };
      } else {
        return { supply_value: 0, tax: 0, total: 0 };
      }
    },
    purchase_accounts() {
      return this.lodash.clonedeep(
        this.purchase_account
          .filter(x =>
            this.purchaseTypes
              .filter(
                y => !['return purchase', 'draft purchase'].includes(y.detail),
              )
              .map(z => z.id)
              .includes(x.purchase.purchase_type_id),
          )
          .sort(
            (a, b) =>
              new Date(b.create_time) - new Date(a.create_time) || b.id - a.id,
          ),
      );
    },
    concatedMaterialInOutList() {
      if (this.managementData != undefined) {
        const inout = this.lodash.clonedeep(
          this.managementData.purchase_material_list.map(
            x => x.material_in_out_list,
          ),
        );

        return inout.reduce((a, b) => a.concat(b));
      } else {
        return [];
      }
    },
    isOutputMaterial() {
      const output = this.lodash.clonedeep(
        this.concatedMaterialInOutList.find(
          x =>
            x.material_in_out_type_id !=
            this.materialInOutType.find(x => x.detail == 'buy in').id,
        ),
      );
      console.log('output', output);
      if (output != undefined) {
        return false;
      } else {
        return true;
      }
    },
    isValidModify() {
      if (
        this.selectedIndex != -1 &&
        this.managementMode &&
        this.purchase_accounts.length > 0
      ) {
        const originData = JSON.stringify(
          this.lodash.clonedeep(
            this.purchase_accounts[this.selectedIndex].purchase_material_list,
          ),
        );
        let modifyData = this.lodash.clonedeep(
          this.managementData.purchase_material_list,
        );
        modifyData = JSON.stringify(
          modifyData.map(x => {
            x.cost = this.$makeNumber(x.cost);
            return x;
          }),
        );

        if (originData == modifyData) {
          return true;
        } else return false;
      } else return false;
    },
  },
  data() {
    return {
      showSearchDate: false,

      //delete modal
      my_modal_title: '입고 취소',
      my_modal_content: '입고 내역을 모두 취소하시겠습니까?',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  methods: {
    reCalCostFromTotalValue(row, newPurchaseValue) {
      const total_value = this.$makeNumber(newPurchaseValue);
      console.log('total_value', total_value);
      const company_vat = this.managementData.purchase.vat_id;

      const product_tax = row.tax_id;
      let rowQuantity = this.$makeNumber(row.quantity);
      if (rowQuantity === 0) {
        row.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '입고일 오류',
            `입고일 조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setInputStartDateToInput', e.target.value);
          this.selectRow(-1);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '구매 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setInputStartDateToInput', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '입고일 오류',
            `입고일 조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setInputEndDateToInput', e.target.value);
          this.selectRow(-1);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '구매 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setInputEndDateToInput', yyyymmdd(new Date()));
      }
    },
    checkValid() {
      if (this.isOutputMaterial) {
        this.my_isModalOpen = true;
      } else {
        this.openOneButtonModal(
          '취소 불가',
          '이미 사용되어진 내역이 존재함으로<br/>취소할 수 없습니다.',
        );
      }
    },
    async submitModify() {
      if (this.managementData != undefined) {
        const payload = this.managementData;
        payload.vat_id = this.managementData.purchase.vat_id;

        await payload.purchase_material_list.forEach(el => {
          el.cost = this.$makeNumber(el.cost);

          const calCost = this.calSupplyTax(
            this.managementData.purchase.vat_id,
            this.$makeNumber(el.tax_id),
            this.$makeNumber(el.quantity),
            this.$makeNumber(el.cost),
          );
          el.supply_value = calCost.supply;
          el.tax = calCost.tax;
          el.total_cost = calCost.total;
        });

        payload.supply_value = this.reCalCost.supply_value;
        payload.tax = this.reCalCost.tax;
        payload.total_value = this.reCalCost.total_value;
        console.log(payload);

        this.showSpinner();
        this.$store
          .dispatch('UPDATE_PURCHASE_ACCOUNT_ALL', payload)
          .then(async () => {
            this.openOneButtonModal('저장 성공', '수정이 완료되었습니다.');
            await this.FETCH_WITH_PAYLOAD(
              'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
              {
                start: this.start_date,
                end: this.end_date,
              },
              '구매 내역',
            );
            this.selectRow(-1);
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '등록 실패',
              '등록 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async submitForm() {
      const payload = this.lodash.clonedeep(this.managementData);
      this.showSpinner();
      this.$store
        .dispatch('DELETE_PURCHASE_ACCOUNT_ALL', payload)
        .then(async () => {
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '구매 내역',
          );
          await this.FETCH(
            'FETCH_PURCHASE_MATERIAL_PURCHASE',
            '미입고 발주항목',
          );
          this.selectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('취소 실패', ' 입고 취소 작업중 오류발생 ');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_PURCHASE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_PURCHASE', {
          start_date: '1950-01-01',
          end_date: '2500-12-30',
          check_only_not_completed: false,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '발주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },

    async selectRow(index) {
      this.$store.commit('setInputSelectedIndexToInput', index);
      if (index != -1) {
        this.$store.commit(
          'setInputManagementDataToInput',
          this.lodash.clonedeep(this.purchase_accounts[index]),
        );
      } else {
        this.$store.commit('setInputManagementDataToInput', null);
      }
    },
  },
  watch: {
    managementMode(newValue) {
      if (newValue == false) {
        this.selectRow(this.selectedIndex);
      }
    },
  },
  async created() {
    if (
      this.order_purchases_type == undefined ||
      this.order_purchases_type.length < 1
    ) {
      await this.FETCH('FETCH_PURCHASE_TYPE', '구매 유형');
    }

    await this.FETCH_PURCHASE();

    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.stores.length == 0) {
      await this.FETCH('FETCH_STORE');
    }

    if (this.start_date == null) {
      var date = new Date();
      await this.$store.commit('setInputEndDateToInput', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      await this.$store.commit('setInputStartDateToInput', yyyymmdd(date2));
    }

    this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE', '자재 입출고 유형');
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
      {
        start: this.start_date,
        end: this.end_date,
      },
      '구매 내역',
    );

    if (this.managementData != null) {
      const findSelectIndex = this.lodash
        .clonedeep(this.purchase_accounts)
        .findIndex(x => x.id == this.managementData.id);

      this.selectRow(findSelectIndex);
    }

    this.showSearchDate = true;
  },
};
</script>

<style lang="scss" scoped></style>
