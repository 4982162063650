<template>
  <div class="modal_body">
    <h3>1.구매처를 선택하여주십시오 (초성검색)</h3>
    <div class="input_wrap">
      <div class="input_text">
        <input
          class="input"
          id="search_text"
          :value="input"
          @input="onInputChange"
          inputmode="none"
          @blur="focus()"
        />
      </div>
    </div>
    <div class="mes_tbl_wrap">
      <table class="mes_tbl select_company_tbl">
        <tbody>
          <tr
            v-for="(item, index) in filteredCompanys"
            :key="item.id"
            @click="
              selectedIndex = 0;
              input = item.name;
            "
            :class="{ active: selectedIndex == index }"
          >
            <td class="text_left">
              {{ item.name }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <simple-keyboard
      v-if="stepIndex == 0"
      @onChange="onChange"
      @onKeyPress="onKeyPress"
      :input="input"
    >
    </simple-keyboard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleKeyboard from '@/layouts/components/SimpleKeyboard.vue';

export default {
  props: {
    company_id: {
      type: Number,
    },
    stepIndex: {
      type: Number,
    },
  },
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      input: '',
      selectedIndex: null,
      firstTouch: false,
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
    }),
    filteredCompanys() {
      if (this.companys.length < 1) {
        return [];
      } else {
        const Hangul = require('hangul-js');
        const filteredArr = this.companys.filter(
          element =>
            element.company_type_id == 2 &&
            (element.name.includes(this.input.trim()) ||
              Hangul.d(element.name, true)
                .map(x => x[0])
                .join('')
                .includes(this.input.trim())),
        );

        return filteredArr;
      }
    },
  },
  mounted() {
    this.focus();
  },
  methods: {
    focus() {
      $('#search_text').focus();
    },
    onInputChange(input) {
      this.input = input.target.value;
      this.selectedIndex = null;
    },
    changeCompany(arg) {
      this.$emit('next', arg.id);
    },
    onChange(input) {
      this.input = input;
      this.selectedIndex = null;
    },
    onKeyPress(button) {
      this.selectedIndex = null;
      console.log('button', button);
      if (button == '{bksp}' && !this.firstTouch) {
        this.input = '';
      }
      this.firstTouch = true;
    },
  },
  watch: {
    selectedIndex(index) {
      this.$emit(
        'onChange',
        index != null ? this.filteredCompanys[index].id : null,
      );
    },
  },
  created() {
    if (this.company_id != null) {
      this.input = this.companys.find(x => x.id == this.company_id).name;
      this.selectedIndex = this.filteredCompanys.findIndex(
        x => x.id == this.company_id,
      );
    }
    this.focus();
    this.firstTouch = false;
  },
};
</script>

<style></style>
