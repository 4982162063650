<template>
  <div>
    <div class="modalPopup material_modal barcode_modal">
      <div class="modal_header">
        <h3 class="title">
          원자재 간편 입고
        </h3>
        <button type="button" class="modal_close" @click="closeModal"></button>
      </div>
      <!-- step 1 -->
      <SelectCompany
        v-if="stepIndex == 0"
        :company_id="company_id"
        @onChange="company_id = $event"
        :stepIndex="stepIndex"
      ></SelectCompany>
      <!-- step 2 -->
      <SelectMaterial
        v-show="stepIndex == 1"
        :company_id="company_id"
        :material_list="material_list"
        :stepIndex="stepIndex"
        @new="stepIndex = 5"
        @onChange="
          new_material_list = new_material_list.filter(x =>
            $event.find(y => y.material_id == x.id),
          )
        "
      ></SelectMaterial>
      <SelectNewMaterial
        v-if="stepIndex == 5"
        @onChange="new_material_list = $event"
        :new_material_list="new_material_list"
        :stepIndex="stepIndex"
      ></SelectNewMaterial>
      <InputConfirm
        v-if="stepIndex == 2"
        :company_id="company_id"
        :material_list="material_list"
        @onChange="input_date = $event"
        :stepIndex="stepIndex"
      ></InputConfirm>
      <div class="modal_footer">
        <button
          type="button"
          class="btn_prev"
          v-if="stepIndex > 0"
          @click="
            () => {
              if (stepIndex == 5) stepIndex = 1;
              else stepIndex--;
            }
          "
        >
          이전
        </button>
        <button
          type="button"
          :disabled="!stepCheck"
          :class="{
            btn_sub2: stepIndex == 2,
            btn_next: stepIndex != 2,
          }"
          @click="
            stepIndex == 5
              ? pushMaterial()
              : stepIndex == 2
              ? submitForm()
              : stepIndex == 1
              ? checkQuantity()
              : stepIndex++
          "
        >
          {{
            stepIndex == 1 ? '작성완료' : stepIndex == 2 ? '입고처리' : '다음'
          }}
        </button>
      </div>
    </div>
    <div class="modal_layer"></div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import PriceMixin from '@/mixins/material_price';
import SelectCompany from '@/layouts/components/SimpleInputModalComponents/SelectCompany.vue';
import SelectMaterial from '@/layouts/components/SimpleInputModalComponents/SelectMaterial.vue';
import SelectNewMaterial from '@/layouts/components/SimpleInputModalComponents/SelectNewMaterial.vue';
import InputConfirm from '@/layouts/components/SimpleInputModalComponents/InputConfirm';

export default {
  mixins: [FETCH_MIXIN, ModalMixin, SpinnerMixin, PriceMixin],
  components: {
    SelectCompany,
    SelectMaterial,
    SelectNewMaterial,
    InputConfirm,
  },
  data() {
    return {
      stepIndex: 0,
      //step 1
      company_id: null,
      material_list: [],
      // step 2
      input_date: yyyymmdd(new Date()),
      //step 5
      new_material_list: [],
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      stores: 'getStores',
    }),
    getPurchaseInfo() {
      if (this.stepIndex == 2) {
        const company_info = this.findInfoFromId(
          this.companys,
          this.company_id,
        );
        let clone = this.lodash.clonedeep(this.material_list);
        let materialList = clone.map(x => ({
          ...x,
          quantity: this.$makeNumber(x.quantity),
          cost: this.$makeNumber(x.cost),
          supply_value: this.calSupplyTax(
            company_info.vat,
            x.tax_id,
            this.$makeNumber(x.quantity),
            this.$makeNumber(x.cost),
          ).supply,
          tax: this.calSupplyTax(
            company_info.vat,
            x.tax_id,
            this.$makeNumber(x.quantity),
            this.$makeNumber(x.cost),
          ).tax,
          total_cost: this.calSupplyTax(
            company_info.vat,
            x.tax_id,
            this.$makeNumber(x.quantity),
            this.$makeNumber(x.cost),
          ).total,
        }));
        return {
          company_id: this.company_id,
          completed: true,
          detail: '',
          incoming_date: this.input_date,
          input_date: this.input_date,
          purchase_ident: this.input_date.replaceAll('-', ''),
          purchase_type_id: 2,
          vat_id: this.findInfoFromId(this.companys, this.company_id).vat,
          purchase_materials: materialList,
          purchase_account: {
            input_date: this.input_date,
            supply_value: materialList
              .map(x => this.$makeNumber(x.supply_value))
              .reduce((a, b) => a + b, 0),
            tax: materialList
              .map(x => this.$makeNumber(x.tax))
              .reduce((a, b) => a + b, 0),
            total_value: materialList
              .map(x => this.$makeNumber(x.total_cost))
              .reduce((a, b) => a + b, 0),
          },
        };
      } else {
        return null;
      }
    },
    /**
     * purchase_material 모양으로 만든다.
     * @returns {
     * material_id : number,
     * unit_id : number,
     * tax_id : number,
     * quantity : number,
     * standard : string,
     * cost : number,
     * supply_value : number,
     * tax : number,
     * total_cost : number,
     * store_id : number,
     * location_id : number,
     * }
     */
    newMaterialInfo() {
      const company_info = this.findInfoFromId(this.companys, this.company_id);
      let material_info = this.lodash.clonedeep(this.new_material_list);
      if (company_info == '' || material_info == []) {
        return {};
      }
      return material_info.map(x => ({
        material_id: x.id,
        unit_id: x.incoming_unit_id,
        tax_id: x.tax,
        quantity: this.$makeNumber(x.quantity),
        standard: x.standard,
        cost: this.$makeNumber(x.cost),
        store_id: x.store_id == undefined ? null : x.store_id,
        location_id: null,
      }));
    },
    stepCheck() {
      if (this.stepIndex == 0 && this.company_id != null) {
        return true;
      } else if (this.stepIndex == 1) {
        return this.material_list.length > 0;
      } else if (this.stepIndex == 5) {
        return this.new_material_list != [];
      } else if (this.stepIndex == 2) {
        return true;
      } else return false;
    },
  },
  methods: {
    checkQuantity() {
      this.material_list.find(x => x.quantity < 1)
        ? this.openOneButtonModal(
            '수량 입력',
            '입고 수량은 필수 입력 사항입니다.',
          )
        : this.stepIndex++;
    },
    submitForm() {
      const payload = this.lodash.clonedeep(this.getPurchaseInfo);
      console.log(payload);
      this.showSpinner();
      this.$store
        .dispatch('INSERT_PURCHASE_AND_INSERT_MATERIAL_CONTROL', payload)
        .then(() => {
          this.openOneButtonModal('입고성공', '입고처리가 완료되었습니다.');
          this.closeModal();
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('입고실패', '입고처리 중 오류');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    pushMaterial() {
      const newMaterialList = this.lodash.clonedeep(this.newMaterialInfo);

      newMaterialList.forEach((el, index) => {
        if (el.total_cost >= 2100000000) {
          this.openOneButtonModal(
            `${index}번쨰 등록 불가`,
            '1회 구매액은 21억을 넘을 수 없습니다.',
          );
          return;
        }
      });
      if (this.material_list.length > 0) {
        this.material_list = newMaterialList.map(x => ({
          ...x,
          quantity: this.material_list.find(y => y.material_id == x.material_id)
            ? this.material_list.find(y => y.material_id == x.material_id)
                .quantity
            : x.quantity,
          cost: this.material_list.find(y => y.material_id == x.material_id)
            ? this.material_list.find(y => y.material_id == x.material_id).cost
            : x.cost,
          store_id: this.material_list.find(y => y.material_id == x.material_id)
            ? this.material_list.find(y => y.material_id == x.material_id)
                .store_id
            : x.store_id,
        }));
      } else {
        this.material_list = newMaterialList;
      }
      this.stepIndex = 1;
    },
    closeModal() {
      this.$emit('onclose');
    },
  },
  async created() {
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.stores.length == 0) {
      await this.FETCH('FETCH_STORE');
    }
  },
};
</script>

<style></style>
